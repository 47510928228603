import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import React from "react";

const InstantDemoPage = ({ data }) => {
  return (
    <>
      <HeroLayout2
        data={data.header}
        theme={data.header.theme}
        formTitle="Watch the 5-Minute Instant Demo"
      />
      <IndustryStripeSmall
        logos={data.featured.logos}
        theme={data.featured.theme}
      />
      <InfoSlider data={data.benefits.list} title={data.benefits.title} />
      <Features
        data={data.features}
        title="What Sets Rhombus Apart"
        color="var(--nuetral-100)"
      />
      <TrustedPartnerSection button={false} />
    </>
  );
};

export default InstantDemoPage;
