import InstantDemoPage from "components/instant-demo/registration/InstantDemoPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../../components/page/GlobalLayout";

export default function InstantDemo() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Rhombus Platform Instant Demo",
        paragraph:
          "See why modern organizations across industries choose Rhombus to protect their spaces.",
        formId: "2a7d4150-3655-4919-a75f-5166708725c9",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      featured: {
        theme: "dark",
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },
      benefits: {
        title: "The Benefits of Rhombus",
        list: [
          {
            tabTitle: "Cloud Infrastructure",
            title: "Reliable Cloud Infrastructure",
            description:
              "No DVR/NVRs required. Manage unlimited cameras, users, devices, and locations from one unified dashboard.",
            image: data.benefit1,
          },
          {
            tabTitle: "Remote Access",
            title: "Native 24/7 Remote Access",
            description:
              "Access your video security from any web browser or mobile device and receive real-time alerts and detection.",
            image: data.benefit2,
          },
          {
            tabTitle: "World-Class Encryption",
            title: "World-Class Enterprise Encryption",
            description:
              "Get total peace of mind with fully encrypted data, automatic firmware updates, no open ports, and third-party audits.",
            image: data.benefit4,
          },
          {
            tabTitle: "Performance",
            title: "Next-Generation Performance",
            description:
              "Save time and futureproof your security with cutting-edge video quality, AI analytics, and plug-and-play scalability .",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "handshake",
          title: "Trusted Partner",
          p:
            "Our mission is to build a true partnership and provide simple, smart, and powerful solutions that fit your unique needs.",
        },
        {
          icon: data.icon2,
          iconAlt: "check mark",
          title: "Industry Veterans",
          p:
            "Since pioneering cloud security cameras, we’ve continued to create new, innovative ways to serve our customers. ",
        },
        {
          icon: data.icon3,
          iconAlt: "security",
          title: "Secure by Default",
          p:
            "Rhombus’ track record speaks for itself with zero-trust security framework and zero breaches.",
        },
        {
          icon: data.icon4,
          iconAlt: "support",
          title: "World-Class Support",
          p:
            "We guarantee you’ll receive a phenomenal experience from a team dedicated to your success.",
        },
      ],
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Watch Rhombus Instant Demo – Business Video Surveillance
          </title>
          <meta
            name="description"
            content="Watch a 5-minute demo to discover how commercial properties, school districts, cities, and enterprise organizations improve safety with Rhombus."
          />
        </Helmet>
        <InstantDemoPage data={pageData} />
      </GlobalLayout>
    );
  };

  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/instant-demo/registration/img/instant-demo-header-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/instant-demo/registration/img/instant-demo-header-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/instant-demo/registration/img/remote-access-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/instant-demo/registration/img/encryption-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: {
          eq: "components/instant-demo/registration/img/performance-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/demo/img/secure-by-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/demo/img/headset.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
